export default ({ get, post, put }) => ({
	getUserInfo() {
		return get('/accounts')
	},
	editUserInfo(params) {
		return put('/accounts', params)
	},
	login(params) {
		return post('/security/login', params)
	},
	request(params) {
		return post('/security/request', params)
	},
	getCategoryList() {
		return get('/category/all')
	},
	getCategory(categoryId) {
		return get(`/category/${categoryId}`)
	},
	getCategorySettings(categoryId) {
		return get(`/settings/category/${categoryId}`)
	},
	editCategorySettings(categoryId, params) {
		return put(`/settings/category/${categoryId}`, params)
	},
	getSettingsWidgetList(categoryId) {
		return get(`/settings/category/${categoryId}/widgets`)
	},
	editSettingsWidgetList(categoryId, params) {
		return put(`/settings/category/${categoryId}/widgets`, params)
	},
	getWidgetSpecific(params) {
		return get(`/widget/${params.widgetId}/category/${params.categoryId}/brands/specific`, params)
	},
	getWidgetComparison(params) {
		return get(`/widget/${params.widgetId}/category/${params.categoryId}/brands/comparison`, params)
	},
	getSettingsChartList(categoryId) {
		return get(`/settings/category/${categoryId}/charts`)
	},
	editSettingsChartList(categoryId, params) {
		return put(`/settings/category/${categoryId}/charts`, params)
	},
	getChartSpecific(params) {
		return get(`/chart/${params.chartId}/category/${params.categoryId}/brands/specific`, params)
	},
	getChartComparison(params) {
		return get(`/chart/${params.chartId}/category/${params.categoryId}/brands/comparison`, params)
	},
	getBrandList(categoryId) {
		return get(`/sku/brand/all/category/${categoryId}`)
	},
	getBrandItem(categoryId) {
		return get(`/sku/brand/category/${categoryId}`)
	},
	getSellers(params) {
		return get('dashboard/sellers', { params })
	},
	getAmounts(params) {
		return get('dashboard/amounts', { params })
	},
	getCompetitorsBrands(params) {
		return get('competitors/brands', { params })
	},
	getCompetitorsWidgetsData(params) {
		return post('competitors/widget', params)
	},
	getBrandsTableData(params) {
		return post('sku/brands', params)
	},
	getBrandPromoPeriod(params) {
		return post('sku/promo-period', params)
	},
	getBrandsSKU(brandId, params) {
		return get(`sku/brand/${brandId}`, { params })
	},
	getSKUAvailabilityPeriod(brandId, params) {
		return get(`sku/brand/${brandId}/availability-period`, { params })
	}
})
