<template lang="pug">
    .accuracy__filter.filter
        Tooltip(
            class="tooltip"
            trigger="clickToToggle"
            @hide="updateResults"
            @show="showTooltip"
            :isNeedClosePopup="isNeedClosePopup"
        )
            template(slot="reference")
                .filter__label.flex.items-center
                    span {{ defaultValue }}
                    ChevronDownIcon.filter__label-icon(
                        :size="iconSize"
                        :class="{'tooltip-active': isActiveTooltip}"
                    )
            template
                ul.list(v-if="isActiveTooltip")
                    li.list__item(
                        v-for="item in value"
                        @click="onFilterClick(item)"
                    )
                      span {{ item.name }}

</template>

<script>

import ConfigInLocalStorage from '@/helpers/LocalStorage/Config'
import FilterInLocalStorage from '@/helpers/LocalStorage/Filter'
import Tooltip from '@/components/Elements/Tooltip.vue'
import ChevronDownIcon from 'vue-material-design-icons/ChevronDown.vue'

export default {
  name: 'SeasonalFilter',

  components: {
    ConfigInLocalStorage,
    FilterInLocalStorage,
    Tooltip,
    ChevronDownIcon,
  },
  props: {
    value: {
      type: [Array, Object],
      default: () => {},
    },
    iconSize: {
      type: [Number, String],
      default: 16,
    },
  },
  data() {
    return {
      currentLSState: null,
      isClicked: false,
      temporaryValues: null,
      isNeedClosePopup: false,
      isActiveTooltip: false,
    }
  },
  computed: {
    defaultValue() {
      return this.value.find(item => item.checked).name;
    },
  },
  methods: {
    onFilterClick(item) {
      const result = this.value.map(element => ({
        ...element,
        checked: element.name === item.name,
      }))

      this.$emit("input", result);
      this.isActiveTooltip = false;
    },
    showTooltip() {
      this.isActiveTooltip = true
    },
    updateResults() {
      this.isActiveTooltip = false
      if (!this.isClicked) return
      this.$emit('update-results')
      this.isClicked = false
    },
  },

}
</script>

<style lang="scss" scoped>
.list {
  &__item {
    font-family: Montserrat, sans-serif;
    padding: 16px 12px;
    font-weight: 400;
    line-height: 16px;
    font-size: 14px;
    cursor: pointer;
  }
}
.tooltip {
  ::v-deep.popper {
    padding: 0;
  }
}
.filter {
  &__list {
    max-height: 300px;
    overflow: auto;
  }

  &__label {
    margin-left: 10px;
    padding: 0 4px 0 10px;
    color: color(white);
    cursor: pointer;
    user-select: none;
    &-icon {
      margin-left: 4px;
      transition: .2s all ease
    }
  }

  &__bottom {
    margin-top: 15px;
    padding-top: 5px;
    border-top: 1px solid color(gray-300);
  }
}
select{
  padding: 0 5px;
  outline: 1px color(gray-400) solid;
  border-radius: 3px;
  color: color(gray-700);

  &:focus{
    outline: none;
  }
}
.tooltip-active {
  transform: rotate(180deg);
}
</style>
