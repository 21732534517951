import AbstractLocalStorage from './AbstractLocalStorage';

class UserLocalStorage extends AbstractLocalStorage {
    constructor(name) {
        super();
        this.name = name;
    }
}

export default new UserLocalStorage('User');
