export default ({ get, post }) => ({
  getKPI({ group_by, stores, categories, brands, subBrands, regions, excludeSku, seasonProducts, dateFrom, dateTo }) {
    return get(`/dashboard/kpi/${group_by}`, {
      baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`,
      params: {
        stores,
        categories,
        brands,
        subBrands,
        excludeSku,
        regions,
        seasonProducts,
        dateFrom,
        dateTo
      }
    });
  },
  getSearch({
    stores,
    categories,
    brands,
    subBrands,
    regions,
    excludeSku,
    seasonProducts,
    dateFrom,
    dateTo,
    offset,
    limit,
    searchQueryIds
  }) {
    return get("/dashboard/shareOfSearch", {
      baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`,
      params: {
        stores,
        categories,
        brands,
        subBrands,
        regions,
        excludeSku,
        seasonProducts,
        dateFrom,
        dateTo,
        offset,
        limit,
        searchQueryIds
      }
    });
  },
  getSearchTerm({
    stores,
    categories,
    brands,
    subBrands,
    regions,
    excludeSku,
    seasonProducts,
    dateFrom,
    dateTo,
    offset,
    limit,
    store,
    searchId
  }) {
    return get("/dashboard/searchTerm", {
      baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`,
      params: {
        stores,
        categories,
        brands,
        subBrands,
        regions,
        excludeSku,
        dateFrom,
        seasonProducts,
        dateTo,
        offset,
        limit,
        store,
        searchId
      }
    });
  },
  getExportShareOfSearch({
    stores,
    categories,
    brands,
    subBrands,
    regions,
    excludeSku,
    seasonProducts,
    dateFrom,
    dateTo
  }) {
    return get("/dashboard/shareOfSearch/export", {
      baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`,
      responseType: "blob",
      params: {
        stores,
        categories,
        brands,
        subBrands,
        excludeSku,
        regions,
        seasonProducts,
        dateFrom,
        dateTo
      }
    });
  },

  getRatingStore({
    category,
    stores,
    categories,
    brands,
    subBrands,
    regions,
    excludeSku,
    seasonProducts,
    dateFrom,
    dateTo,
    offset,
    limit,
    searchQuery
  }) {
    return get("/dashboard/ratings/store", {
      baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`,
      params: {
        stores,
        categories,
        category,
        brands,
        subBrands,
        regions,
        excludeSku,
        seasonProducts,
        dateFrom,
        dateTo,
        offset,
        limit,
        searchQuery
      }
    });
  },
  getExportRatingsAndReviews({
    stores,
    categories,
    brands,
    subBrands,
    regions,
    excludeSku,
    seasonProducts,
    dateFrom,
    dateTo
  }) {
    return get("/dashboard/ratings/export", {
      baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`,
      responseType: "blob",
      params: {
        stores,
        categories,
        brands,
        subBrands,
        regions,
        excludeSku,
        seasonProducts,
        dateFrom,
        dateTo
      }
    });
  },
  getAvailability({
    category,
    stores,
    categories,
    brands,
    subBrands,
    regions,
    excludeSku,
    seasonProducts,
    dateFrom,
    dateTo,
    offset,
    limit,
    status,
    searchQuery,
    provider,
    type
  }) {
    return get(`/dashboard/availability/stores`, {
      baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`,
      params: {
        stores,
        categories,
        category,
        brands,
        subBrands,
        regions,
        excludeSku,
        seasonProducts,
        dateFrom,
        dateTo,
        offset,
        limit,
        status,
        searchQuery,
        provider,
        type
      }
    });
  },
  getExportPortfolioAvailability({
    stores,
    categories,
    brands,
    subBrands,
    regions,
    excludeSku,
    seasonProducts,
    dateFrom,
    dateTo
  }) {
    return get("/dashboard/availability/export", {
      baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`,
      responseType: "blob",
      params: {
        stores,
        categories,
        brands,
        subBrands,
        regions,
        excludeSku,
        seasonProducts,
        dateFrom,
        dateTo
      }
    });
  },
  getTaxonomyPlacement({
    stores,
    categories,
    brands,
    subBrands,
    regions,
    excludeSku,
    seasonProducts,
    dateFrom,
    dateTo,
    offset,
    limit
  }) {
    return get(`/dashboard/taxonomyPlacement`, {
      baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`,
      params: {
        stores,
        categories,
        brands,
        subBrands,
        regions,
        excludeSku,
        seasonProducts,
        dateFrom,
        dateTo,
        offset,
        limit
      }
    });
  },
  getShareOfTaxonomy({
    stores,
    categories,
    brands,
    subBrands,
    regions,
    excludeSku,
    seasonProducts,
    dateFrom,
    dateTo,
    offset,
    limit
  }) {
    return get(`/dashboard/taxonomyShare`, {
      baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`,
      params: {
        stores,
        categories,
        brands,
        subBrands,
        regions,
        excludeSku,
        seasonProducts,
        dateFrom,
        dateTo,
        offset,
        limit
      }
    });
  },
  getTaxonomyProducts({
    stores,
    categories,
    brands,
    subBrands,
    regions,
    excludeSku,
    seasonProducts,
    dateFrom,
    dateTo,
    offset,
    limit
  }) {
    return get(`/dashboard/taxonomyProducts`, {
      baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`,
      params: {
        stores,
        categories,
        brands,
        subBrands,
        regions,
        excludeSku,
        seasonProducts,
        dateFrom,
        dateTo,
        offset,
        limit
      }
    });
  },
  getProductFails({
    stores,
    categories,
    brands,
    subBrands,
    regions,
    excludeSku,
    seasonProducts,
    dateFrom,
    dateTo,
    offset,
    limit
  }) {
    return get(`/dashboard/productFails`, {
      baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`,
      params: {
        stores,
        categories,
        brands,
        subBrands,
        regions,
        excludeSku,
        seasonProducts,
        dateFrom,
        dateTo,
        offset,
        limit
      }
    });
  },
  getImageAccuracy({
    stores,
    categories,
    brands,
    subBrands,
    regions,
    seasonProducts,
    excludeSku,
    dateFrom,
    dateTo,
    offset,
    limit,
    type
  }) {
    return get("/dashboard/imageAccuracy", {
      baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`,
      params: {
        stores,
        categories,
        brands,
        subBrands,
        regions,
        excludeSku,
        seasonProducts,
        dateFrom,
        dateTo,
        offset,
        limit,
        type
      }
    });
  },
  getExportImageAccuracy({
    stores,
    categories,
    brands,
    subBrands,
    excludeSku,
    regions,
    seasonProducts,
    dateFrom,
    dateTo
  }) {
    return get("/dashboard/imageAccuracy/export", {
      baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`,
      responseType: "blob",
      params: {
        stores,
        categories,
        brands,
        subBrands,
        regions,
        excludeSku,
        seasonProducts,
        dateFrom,
        dateTo
      }
    });
  },
  getTaxonomyProductDetails({
    stores,
    categories,
    brands,
    subBrands,
    regions,
    excludeSku,
    seasonProducts,
    dateFrom,
    dateTo
  }) {
    return get("dashboard/shareOfTaxonomy/productDetails/xlsx", {
      baseURL: process.env.VUE_APP_API_BASE_URL || "http://localhost:3000",
      responseType: "blob",
      params: {
        stores,
        categories,
        brands,
        subBrands,
        regions,
        excludeSku,
        seasonProducts,
        dateFrom,
        dateTo
      }
    });
  },
  getImageProducts({
    stores,
    categories,
    brands,
    subBrands,
    regions,
    excludeSku,
    seasonProducts,
    dateFrom,
    dateTo,
    offset,
    limit
  }) {
    return get("/dashboard/imageProducts", {
      baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`,
      params: {
        stores,
        categories,
        brands,
        subBrands,
        regions,
        seasonProducts,
        excludeSku,
        dateFrom,
        dateTo,
        offset,
        limit
      }
    });
  },
  getImageCompliance({
    stores,
    categories,
    brands,
    subBrands,
    regions,
    excludeSku,
    seasonProducts,
    dateFrom,
    dateTo
  }) {
    return get("/dashboard/imageCompliance", {
      baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`,
      params: {
        stores,
        categories,
        brands,
        subBrands,
        regions,
        excludeSku,
        seasonProducts,
        dateFrom,
        dateTo
      }
    });
  },
  getContentIntegrity({
    stores,
    categories,
    brands,
    subBrands,
    regions,
    excludeSku,
    seasonProducts,
    dateFrom,
    dateTo,
    ruleFilter,
    searchQuery
  }) {
    return get("/dashboard/contentIntegrity", {
      baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`,
      params: {
        stores,
        categories,
        brands,
        subBrands,
        regions,
        excludeSku,
        seasonProducts,
        dateFrom,
        dateTo,
        ruleFilter,
        searchQuery
      }
    });
  },
  getExportContentIntegrity({
    stores,
    categories,
    brands,
    subBrands,
    regions,
    excludeSku,
    seasonProducts,
    dateFrom,
    dateTo
  }) {
    return get("/dashboard/contentIntegrity/export", {
      baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`,
      responseType: "blob",
      params: {
        stores,
        categories,
        brands,
        subBrands,
        regions,
        excludeSku,
        seasonProducts,
        dateFrom,
        dateTo
      }
    });
  },
  getExportSKUBrands(params) {
    return post("/sku/brands/export", params, {
      baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`,
      responseType: "blob"
    });
  },
  getExportSKUPromoPeriod(params) {
    return post("/sku/promo-period/export", params, {
      baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`,
      responseType: "blob"
    });
  },
  getExportSKUBrandById(params) {
    const brandId = params.brandId;
    delete params.brandId;
    return get(`sku/brand/${brandId}/export`, {
      baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`,
      responseType: "blob",
      params
    });
  },
  getExportSKUBrandByIdAvailabilityPeriod(params) {
    const brandId = params.brandId;
    delete params.brandId;
    return get(`sku/brand/${brandId}/availability-period/export`, {
      baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`,
      responseType: "blob",
      params
    });
  },

	getExportDashboard(activeTab, params) {
    return get(`/dashboard/kpi/${activeTab}/export?`, {
      baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`,
      responseType: "blob",
      params
    });
  },

  login({ email, password }) {
    return post("/security/login", {
      baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`,
      params: {
        email,
        password
      }
    });
  },

  // TODO Возможно, вынести в common API (api/service)
  getStores({ categories }) {
    return get("/dashboard/stores/groups", {
      baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`,
      params: {
        categories
      }
    });
  },
  getBrands({ stores, categories }) {
    return get("/dashboard/brands", {
      baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`,
      params: {
        stores,
        categories
      }
    });
  },
  getParentCategories() {
    return get("/dashboard/filters", {
      baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`
    });
  },
  getCategories({ stores }) {
    return get("/dashboard/categories", {
      baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`,
      params: {
        stores
      }
    });
  },
  getRegions() {
    return get("/dashboard/regions", {
      baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`
    });
  },
  getSku(params, options) {
    return post("/dashboard/sku-list", {
      baseURL: process.env.VUE_APP_API_BASE_URL || `http://localhost:3000`,
      ...params,
    }, options)
  }
});
