import api from '@/api'

export default {
	namespaced: true,
	state: {
		stores: [],
		categories: [],
		brands: [],
		regions: [],
		sku: [],
		directory: {
			stores: {},
			categories: {},
			brands: {},
			regions: {},
		},
		secondPeriod: {
			from: '',
			to: '',
		},
		skuCount: 0,

		dashboardActiveTab: 'retailers',
		skuFilterAbortController: null,
	},
	getters: {
		getStoreById: ({ directory }) => (id) => ( directory.stores[id] ),
		getCategoryById: ({ directory }) => (id) => ( directory.categories[id] ),
		getBrandById: ({ directory }) => (id) => ( directory.brands[id] ),
		getRegionById: ({ directory }) => (id) => ( directory.regions[id] ),
	},
	mutations: {
		setSku(state, sku) {
			state.sku = sku;
		},
		setSkuCount(state, skuCount) {
			state.skuCount = skuCount;
		},
		setStores(state, stores) {
			state.stores = stores;
		},
		setCategories(state, categories) {
			state.categories = categories;
		},
		setBrands(state, brands) {
			state.brands = brands;
		},
		setRegions(state, regions) {
			state.regions = regions;
		},
		setDirectory(state, { name, data }) {
			state.directory[name] = data;
		},
		saveSecondPeriod(state, secondPeriod) {
			state.secondPeriod.from = secondPeriod.from
			state.secondPeriod.to = secondPeriod.to
		},
		setDashboardActiveTab(state, dashboardActiveTab) {
			state.dashboardActiveTab = dashboardActiveTab;
		},
		setAbortController(state) {
			state.skuFilterAbortController = new AbortController();
		}
	},
	actions: {
		async fetchStores({ commit }, params) {
			const stores = await api.nestle.getStores(params);
			if (!stores) return;

			const allStoresArr = [];

			stores.forEach(group =>
				Object.values(group.subGroups).forEach(subGroups =>
					allStoresArr.push(...subGroups.markets)
				)
			);

			const YANDEX_POKUPKI_APP = 'Yandex.pokupki_APP'
			const YANDEX_MARKET_APP = 'Yandex.market_APP'

			const index = allStoresArr.findIndex((item) => item.name === YANDEX_POKUPKI_APP)
			if (index != -1) {
				allStoresArr[index].name = YANDEX_MARKET_APP;
			}

			commit('setStores', stores);

			const dataForDirectory = allStoresArr.reduce(
				(acc, { id, name }) => ({ ...acc, [id]: name }),
				{}
			);
			commit('setDirectory', {
				name: 'stores',
				data: dataForDirectory,
			});
		},
		async fetchCategories({ commit }, params) {
			const categories = await api.nestle.getCategories(params);
			if (!categories) return;
			commit('setCategories', categories);

			const dataForDirectory = categories.reduce((acc, {id, name}) => (
				{...acc, [id]: name}), {}
			);
			commit('setDirectory', {
				name: 'categories',
				data: dataForDirectory,
			});
		},

		async fetchBrands({ commit }, params) {
			const brands = await api.nestle.getBrands(params);
			if (!brands) return;

			const allBrands = [];

			brands.forEach((brand) => {
				Object.values(brand.sub).forEach((subBrand, index) => {
					const returnBrand = {
						groupName: brand.name,
						name: subBrand,
						parentId: brand.id,
						parentName: brand.name,
						checked: false,
						id: index + 1,
						isSubBrand: true,
					}

					allBrands.push(returnBrand);
				});
			});

			commit('setBrands', allBrands);

			const dataForDirectory = brands.reduce((acc, {id, name}) => (
				{...acc, [id]: name}), {}
			);
			commit('setDirectory', {
				name: 'brands',
				data: dataForDirectory,
			});
		},
		async fetchRegions({ commit }) {
			const regions = await api.nestle.getRegions();
			if (!regions) return;
			commit('setRegions', regions);

			const dataForDirectory = regions.reduce((acc, {id, name}) => (
				{...acc, [id]: name}), {}
			);
			commit('setDirectory', {
				name: 'regions',
				data: dataForDirectory,
			});
		},
		async fetchSku({ commit, state }, params) {
			try {
				if (state.skuFilterAbortController) {
					state.skuFilterAbortController?.abort();
				}
				commit('setAbortController');
				const response = await api.nestle.getSku(params, {
					signal: state.skuFilterAbortController?.signal
				});

				if (!response) return;

				commit('setSku', response.sku?.map(item => ({ ...item, checked: false })));
				commit('setSkuCount', response.count);

				const dataForDirectory = response.sku.reduce((acc, {id, name}) => (
					{...acc, [id]: name}), {}
				);
				commit('setDirectory', {
					name: 'brands',
					data: dataForDirectory,
				});
			} catch (e) {
				commit('setSku', []);
				commit('setSkuCount', 0);
			}
		},

		saveSecondPeriod({ commit }, secondPeriod) {
			commit('saveSecondPeriod', secondPeriod)
		},
	},
}
