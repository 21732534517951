import api from "@/api";
import { ExportSpreadsheet } from "@/helpers/ExportSpreadsheet";

export default {
  namespaced: true,
  state: {
    activeTab: "Общая информация",
    brandId: null,
    selectedCompetitorsIds: [],
    selectedSellersIds: [],
    selectedAmountsNames: [],
    requestParams: {
      dateFrom: "",
      dateTo: "",
      ownBrands: [],
      stores: [],
      categories: [],
      regions: [],
      competitorsBrands: [],
      limit: null,
      offset: null,
      sortBy: "",
      sortOrder: "",
      searchQuery: ""
    }
  },
  getters: {},
  mutations: {
    setActiveTab(state, activeTab) {
      state.activeTab = activeTab;
    },
    setBrandId(state, brandId) {
      state.brandId = brandId;
    },
    setRequestParams(state, requestParams) {
      state.requestParams = requestParams;
    },
    setSelectedCompetitorsIds(state, selectedCompetitorsIds) {
      state.selectedCompetitorsIds = selectedCompetitorsIds;
    },
    setSelectedSellersIds(state, selectedSellersIds) {
      state.selectedSellersIds = selectedSellersIds;
    },
    setSelectedAmountsNames(state, selectedAmountsNames) {
      state.selectedAmountsNames = selectedAmountsNames;
    }
  },
  actions: {
    getExportBrands({ state }) {
      const isFirstTab = state.activeTab === "Общая информация";
      const requestApi = isFirstTab
        ? api.nestle.getExportSKUBrands
        : api.nestle.getExportSKUPromoPeriod;
      const nameFilte = isFirstTab ? "export-brands" : "export-promo-period";

      ExportSpreadsheet(requestApi, state.requestParams, nameFilte).catch(err =>
        console.error(err)
      );
    },
    getExportSKUBrand({ state }) {
      const isFirstTab = state.activeTab === "Общая информация";
      const requestApi = isFirstTab
        ? api.nestle.getExportSKUBrandById
        : api.nestle.getExportSKUBrandByIdAvailabilityPeriod;
      console.log(isFirstTab);
      const nameFilte = isFirstTab
        ? "export-brand-skus"
        : "export-availability-period";

      const params = { ...state.requestParams };
      delete params.competitorsBrands;

      ExportSpreadsheet(
        requestApi,
        { ...params, brandId: state.brandId },
        nameFilte
      ).catch(err => console.error(err));
    }
  }
};
