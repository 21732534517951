<template lang="pug">
.filter-wrapper
    Tooltip(trigger="clickToToggle" :placement="tooltipPlacement" flat @hide="$emit('hide')")
        template(slot="reference")
            slot(name="activator")
                button.filter-button
                    | {{ name }}
                    ChevronDownIcon.filter-icon(
                        :size="16"
                        :class="{'active': isOpened}"
                    )

        .tooltip-content
          .search-wrapper(v-if="hasSearch")
            input.search-input(v-model="searchValue" :placeholder="searchPlaceholder")
            span.search-icon
            button.clear-search(v-if="searchValue" @click.stop="clearSearch")
              span.clear-icon
                CloseIcon
          .no-items(v-if="!items.length")
            | {{ emptyLabel }}
          template(v-else)
              .disclaimer(v-if="disclaimer")
                  | {{ disclaimer }}

              button.select-all(
                  v-show="hasSelectAll && !searchValue"
                  @click="hasCheckedItem ? $emit('clear-all') : $emit('select-all')"
              )
                  | {{ hasCheckedItem ? 'Clear all' : 'Select all'}}


              .loader(v-if="isItemsLoading && isServerSearch")
                | Пожалуйста, подождите...
              .checkboxes-wrapper(v-else)
                  .no-items(v-if="searchValue && !currentItems.length")
                      | Ничего не найдено. Попробуйте изменить параметры поиска.
                  MultiCheckboxesTree(
                      v-else-if="isTree"
                      :items="currentTreeItems"
                      :limit="limit"
                      :checkedStores="checkedStores"
                      :isMainPage="isMainPage"
                      :multiTree="isMultiTree"
                  )
                  MultiSelect.select(
                      v-else
                      :items="currentItems"
                      :limit="limit"
                      @select-checkbox="$emit('select-item', $event)"
                  )
</template>

<script>
import ConfigInLocalStorage from '@/helpers/LocalStorage/Config'
import ChevronDownIcon from 'vue-material-design-icons/ChevronDown.vue';
import MultiSelect from '@/components/Elements/MultiSelect/MultiSelect.vue'
import MultiCheckboxesTree from '@/components/Elements/MultiCheckboxesTree.vue';
import Tooltip from '@/components/Elements/Tooltip.vue';
import CloseIcon from '@/assets/svg/close.svg';
import FilterInLocalStorage from '@/helpers/LocalStorage/Filter'

export default {
    components: {
        FilterInLocalStorage,
        ConfigInLocalStorage,
        ChevronDownIcon,
        MultiSelect,
        MultiCheckboxesTree,
        Tooltip,
        CloseIcon,
    },

    props: {
      isItemsLoading: {
        type: Boolean,
        default: false
      },
      isServerSearch: {
        type: Boolean,
        default: false,
      },
      emptyLabel: {
        type: String,
        default: 'По выбранным маркетплейсам нет данных'
      },
      name: {
          type: String,
          required: true,
      },
      items: {
          type: Array,
          required: true,
      },
      hasSelectAll: {
          type: Boolean,
          default: false,
      },
      hasSearch: {
          type: Boolean,
          default: true,
      },
      searchPlaceholder: {
          type: String,
          default: '',
      },
      disclaimer: {
          type: String,
          default: '',
      },
      isMultiTree: {
        type: Boolean,
        default: true,
      },
      tooltipPlacement: {
          type: String,
          default: 'bottom',
      },
      isTree: {
          type: Boolean,
          default: false
      },
      isMainPage: {
        type: Boolean,
        default: false
      },
      storageIsFull: {
        type: Boolean,
        default: false,
      },
      checkedStores: {
        type: Number,
        default: 0,
      },
    },

    data() {
        return {
            isOpened: false,
            searchValue: '',
            limit: null,
        }
    },

    computed: {
        hasCheckedItem() {
            return this.items.some((item) => item.checked);
        },

        currentItems() {
            if (this.isServerSearch) return this.items;
            const searchString = this.searchValue.toLowerCase();
            return this.items.filter((item) => item.name.toLowerCase().includes(searchString));
        },

        currentTreeItems() {
            if (!this.isTree) return {};
            const groupsNames = this.currentItems.map((item) => item.groupName);
            const groupsBranch = Array.from(new Set(groupsNames));

            const treeItems = {};

            groupsBranch.forEach((group) => {
                treeItems[group] = this.currentItems.filter((item) => item.groupName === group);
            })

            return treeItems;
        }
    },

    methods: {
      clearSearch() {
          this.searchValue = '';
      },
      clearLimit() {
        this.limit = null
      },
      checkItems(amount) {
        for (let i = 0; i < amount; i++) {
          this.$set(this.items, i, { ...this.items[i], checked: true })
        }
      },
      setLimit() {
        this.limit = ConfigInLocalStorage.getLimits(this.name) || null
      },
      uncheckedItems() {
        let uncheckedAmount = 0
        for (let i = this.items.length-1; uncheckedAmount < (this.checkedStores - this.limit); i--) {
          if (
            Object.prototype.hasOwnProperty.call(this.items[i], 'checked') && 
            this.items[i].checked
          ) {
            this.$set(this.items, i, { ...this.items[i], checked: false })
            uncheckedAmount = uncheckedAmount + 1;
          }
        }
      },
    },
    watch: {
      searchValue: {
        handler(value) {
          if (this.isServerSearch) this.$emit('update:search', value);
        }
      },
      'isMainPage': {
        immediate: true,
        handler(value) {
          if (this.name !== 'stores') return
          if (value) {
            this.clearLimit()
            if (!this.storageIsFull) {
              this.checkItems(this.items.values.length)
            }
          } else {
            this.setLimit()
            if (this.checkedStores > this.limit) {
              this.uncheckedItems()
            }
          }
        }
      },
  },
}
</script>

<style scoped lang="scss">

.loader {
  text-align: center;
  padding: 8px 0;
}

.filter-wrapper {
    position: relative;
}

.filter-button {
    display: flex; 
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;
    color: #fff;
    outline: none;

    &:focus {
        outline: none;
    }
}

.filter-icon {
    margin-left: 4px;
}

.tooltip-content {
    min-width: 333px;
}

.disclaimer {
    position: relative;
    padding: 16px 12px;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #000000;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 12px;
        right: 12px;
        height: 1px;
        background-color: #E7E7E7;
    }
}

.search-wrapper {
    position: relative;
    padding: 10px 12px;
    width: 100%;
    height: 48px;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 12px;
        right: 12px;
        height: 1px;
        background-color: #E7E7E7;
    }
}

.search-input {
    padding-left: 32px;
    padding-right: 32px;
    width: 100%;
    height: 100%;
    font-family: 'OpenSans';
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    color: #000000;
    outline: none;

    &::placeholder {
        font-family: 'OpenSans';
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        color: #A3AEBE;
    }
}

.search-icon {
    position: absolute;
    display: block;
    left: 20px;
    top: 16px;
    width: 16px;
    height: 16px;
    background-image: url('~@/assets/img/search.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.clear-search {
    position: absolute;
    padding: 4px;
    right: 12px;
    top: 18px;
    width: 18px;
    height: 18px;
    outline: none;

    &:focus {
        outline: none;
    }
}

.clear-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    
    svg {
        width: 100%;
        height: 100%;

        path {
            fill: #9D9DB0;
        }
    }
}

.select-all {
    position: relative;
    padding: 16px 12px;
    width: 100%;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #212121;
    outline: none;

    &:focus {
        outline: none;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: #9E9E9E;
    }
}

.checkboxes-wrapper {
    position: relative;
    max-height: 300px;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #7A7A7A;
        border-radius: 7px;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: #9E9E9E;
    }
}

.no-items {
    padding: 16px;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #212121;
}
</style>
